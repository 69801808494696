.footer_logo {
    height: 50px;
}
.logo{ 
    width: 100px;
}

.circle {
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    color: #FFF;
}

.p-6 {
    padding: 80px 20px;
}

.max-w {
    max-width: 400px;
}

.loading {
    width: 100px;
    height: 100px;
    margin: auto;
}

.status_text {
    color: #555555;
    font-size: 25px;
}

.info_text {
    color: #555555;
    font-size: 14px;
}

.cancel_text {
    font-size: 12px;
}